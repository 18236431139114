@import '../../../../styles/customMediaQueries.css';

.root {
  background-image: url('../../../../assets/bg_mobile.png');

  background-color: var(--colorWhite);
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (--viewportMedium) {
    background-image: url('../../../../assets/bg.png');
    height: 465px;
    border-radius: 16px;
    margin: 0 24px;
  }
}

.title {
  color: #f5f5f0;
  font-size: 40px;
  line-height: 60px;
  font-weight: var(--fontWeightBold);
  font-family: 'Oswald', sans-serif;
  letter-spacing: 0.5px;

  @media (--viewportMedium) {
    font-size: 56px;
    line-height: 68px;
    width: 80%;
  }
}

.content {
  line-height: 28px;
  margin-top: 16px;
  width: 100%;
  text-shadow: 0px 4px 8px 0px #00000033;

  font-weight: var(--fontWeightSemiBold);
  color: var(--colorWhite);

  margin-bottom: 24px;
  font-size: 24px;

  @media (--viewportMedium) {
    line-height: 32px;
    margin-bottom: 48px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  justify-content: space-between;

  @media (--viewportMedium) {
    justify-content: start;
  }
}

.btn {
  width: auto;
  font-size: 16px;

  background-color: var(--marketplaceColor);
  text-transform: uppercase;
  color: var(--colorWhite);
  font-weight: var(--fontWeightSemiBold);
  min-height: 42px;
  min-width: 100px;
  width: 48%;
  border-radius: 8px;
  padding: 12px 12px;

  @media (--viewportMedium) {
    padding: 10px 12px;
    width: 180px;
  }
}

.btn:hover,
.btn:focus {
  background-color: var(--marketplaceColorDark);
}

.transparent {
  background-color: transparent;
  border: 1px solid white;

  &:hover {
    border: 1px solid transparent;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    width: 42%;
  }
}

.heroWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  padding: 0 20px 20px 20px;

  @media (--viewportMedium) {
    margin: 0 auto;
    margin-top: 20px;
    justify-content: start;
  }
}
