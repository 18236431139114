@import '../../../../styles/customMediaQueries.css';

.root {
  overflow-x: scroll;
  display: none;

  @media (--viewportMedium) {
    display: block;
    overflow-x: unset;
    background-color: var(--colorWhite);
    width: 100%;
    position: fixed;
    top: var(--topbarHeightDesktop);
    left: 0;
    z-index: var(--zIndexTopbar);

    height: 40px;
  }
}

.menuWrapper {
  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 100%;
    z-index: var(--zIndexTopbar);

    margin: 0 24px;
    color: var(--colorBlack);
    font-weight: var(--fontWeightRegular);
    margin-bottom: 20px;
  }
}

.categoryLabel {
  height: 100%;
  display: flex;
  padding: 0 16px;
  align-items: center;
  cursor: pointer;
}

.categoryLabelHover {
  background-color: var(--colorBlack);
  color: var(--colorWhite);
}

.categoryPopup {
  height: 464px;
  width: 100%;
  padding: 32px 40px;

  position: absolute;
  top: 38px;
  left: 0;

  background-color: var(--colorWhite);
  border-radius: 0 0 16px 16px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.dropDownContent {
  display: none;
  position: relative;
  background-color: var(--colorWhite);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  color: var(--colorBlack);
  text-align: start;

  @media (--viewportMedium) {
    position: absolute;
    min-width: 200px;
    z-index: 1;
  }
}

.subCate {
  text-decoration: unset;
  font-size: 16px;
  line-height: 40px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorBlack);
  padding: 8px 16px;
}

.subCate:hover,
.subCate:focus {
  background-color: #f9f9f9;
}

.cateItem:hover .dropDownContent {
  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
  }
}

.icon {
  font-size: 14px;
}

.mobileWrapper {
  display: flex;
  flex-direction: column;
}

.mobileSubCate {
  position: absolute;
  width: 100vw;
  background-color: var(--colorWhite);
  top: 100px;
  left: 0;
}

.popupTitle {
  font-weight: var(--fontWeightSemiBold);
}

.poptupCategories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.popupCategory {
  border-bottom: 1px solid #f5f5f0;
  padding: 16px 0;
  width: 48%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    color: #cd151f;
    font-weight: var(--fontWeightSemiBold);
    transition: font-weight 0.2s ease;
  }
}

.categoryPopup {
  display: flex;
}

.categoryPopupLeft {
  flex: 4;
}

.categoryPopupRight {
  flex: 6;
  padding-left: 16px;
}

.seeAll {
  font-weight: var(--fontWeightSemiBold);
  text-transform: capitalize;
  margin-top: 16px;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }
}

.recommendedTitle {
  font-weight: var(--fontWeightSemiBold);
}

.recommendedOptions {
  display: flex;
  margin-top: 16px;
  gap: 16px;
}

.recommendedLeft {
  display: flex;
  gap: 16px;
  flex: 6;
}

.primaryCardImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;

  &:hover {
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
  }
}

.primaryCardTitle {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  color: var(--colorWhite);
  text-transform: uppercase;
  text-wrap: nowrap;
  font-weight: var(--fontWeightSemiBold);
}

.primaryCard {
  position: relative;
  cursor: pointer;
}

.recommendedRight {
  width: 100%;
  flex: 4;
}

.secondCardLabel {
  border-bottom: 1px solid #f5f5f0;
  padding: 16px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    color: #cd151f;
    font-weight: var(--fontWeightSemiBold);
    transition: font-weight 0.2s ease;
  }
}
