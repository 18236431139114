.img {
  border-radius: 50%;
  width: 90%;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
}

.text {
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  width: 90%;
  text-align: center;
  margin-top: 8px;
}