@import '../../../../styles/customMediaQueries.css';

.root {
  background-color: #922214;
  color: var(--colorWhite);
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 580px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
    height: 480px;
  }
}

.img {
  width: auto;
  height: 48px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  margin-top: 24px;
  gap: 10px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.btn {
  cursor: pointer;
  height: 48px;
  object-fit: contain;

}

.heading {
  font-size: 32px;
  font-weight: var(--fontWeightBold);
  line-height: 48px;

  @media (--viewportMedium) {
    font-size: 40px;
  }
}

.content {
  font-size: 20px;
  margin-top: 24px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportMedium) {
    line-height: 60px;
    margin-top: 0;
  }
}

.contentWrapper {
  padding: 40px 24px 0;

  @media (--viewportMedium) {
    margin: auto;
    flex: 1;
  }
}

.mainWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.images {
  flex: 1;
}

.desktopImg {
  display: none;
  @media (--viewportMedium) {
    display: block;
    background-image: url('../../../../assets/app_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 480px;
    height: 500px;
    margin-top: 120px;
  }
}

.mobileImg {
    display: block;
    background-image: url('../../../../assets/app_bg_mobile.png');
    background-position:  bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 274px;
  @media (--viewportMedium) {
    display: none;
  }
}
