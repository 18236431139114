@import '../../../../styles/customMediaQueries.css';

.root {
    padding: 48px 0 20px;

    @media (--viewportLarge) {
        padding: 64px 0;
    }
}

.title {
    font-size: 24px;
    color: var(--colorBlack);
    font-weight: var(--fontWeightBold);
}

.rowGrid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 40%;
    overflow: scroll;
    grid-gap: 20px;
    padding: 20px 0;

    @media (--viewportMedium) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        overflow: hidden;
    }
}

.sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 0 24px;    
}

.line {
    display: none;
    flex: 8;
    height: 1px;
    background-color: var(--colorGrey300);

    @media (--viewportMedium) {
        display: block;
    }
}

.shopAllBtn {
    width: 100px;
    font-size: 16px;
  
    background-color: var(--marketplaceColor);
    text-transform: uppercase;
    color: var(--colorWhite);
    font-weight: var(--fontWeightSemiBold);
    min-height: 32px;
  
    border-radius: 8px;
  
    @media (--viewportMedium) {
      width: auto;
      min-height: 42px;
  
      border-radius: 8px;
      padding: 0 30px;
    }
  
    &:hover,
    &:focus {
      background-color: var(--marketplaceColorDark);
    }
  }

.title {
    font-family: 'Oswald', sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    text-transform: uppercase;
    color: var(--colorBlack);
    color: var(--marketplaceColorDark);
    
    @media (--viewportMedium) {
        font-size: 32px;
    }
}

.slider {
    margin: 24px 0 24px 24px;
}
