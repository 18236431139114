@import '../../../../styles/customMediaQueries.css';

.root {
  color: var(--colorWhite);
  height: 116px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  background-image: url('../../../../assets/banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 40px;
  }
}

.title {
  font-family: 'Oswald', sans-serif;
  font-size: 18px;
  font-weight: var(--fontWeightSemiBold);
  @media (--viewportMedium) {
    font-size: 32px;
  }
}

.root a {
  color: var(--colorWhite);
  font-weight: var(--fontWeightSemiBold);
  text-decoration: underline;
}

.transparentBtn {
  width: auto;
  font-size: 16px;

  background-color: transparent;
  color: var(--colorWhite);
  font-weight: var(--fontWeightSemiBold);
  min-height: 42px;
  border: 1px solid var(--colorWhite);

  border-radius: 8px;
  padding: 0 20px;

  &:hover,
  &:focus {
    background-color: var(--marketplaceColorDark);
  }
}
