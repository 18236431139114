@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/marketplaceDefaults.css';

.root {
    color: var(--colorBlack);
}

.container {
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (--viewportMedium) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.btn {
    width: auto;
    min-height: 42px;
    font-size: 16px;

    background-color: transparent;
    color: var(--colorWhite);
    border: 1px solid var(--colorWhite);
    font-weight: var(--fontWeightSemiBold);

    border-radius: 8px;
    padding: 0 34px;

    justify-self: center;
    align-self: start;

    @media (--viewportMedium) {
        font-size: 20px;
    }
}

.btn:hover,
.btn:focus {
    background-color: var(--colorGrey700);
}

.leftSide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    justify-content: center;
    background-color: #922214;
    color: var(--colorWhite);
}

.imgWrapper {
    display: flex;
    flex: 1;

    @media (--viewportMedium) {
        justify-content: flex-end;
        width: 660px;
    }
}

.img {
    width: 100%;
    object-fit: cover;
    height: 300px;
    @media (--viewportMedium) {    
        height: 585px;
    }
}

.title {
    font-size: 20px;
    color: var(--colorWhite);
    font-weight: var(--fontWeightBold);
    font-family: 'Oswald', sans-serif;

    @media (--viewportMedium) {
        font-size: 40px;
        line-height: 44px;

    }
}

.description {
    font-size: 16px;
    white-space: pre-line;
    margin-top: 24px;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
        font-size: 20px;
        margin-top: 32px;
    }
}

.content {
    font-size: 16px;
    white-space: pre-line;
    line-height: 22px;
    font-weight: var(--fontWeightRegular);
    margin-top: 16px;

    padding-bottom: 20px;

    @media (--viewportMedium) {
        padding-bottom: 60px;
        font-size: 20px;
        line-height: 32px;
        margin-top: 32px;
    }
}

.recommerce {
    @media (--viewportMedium) {
        flex-direction: row-reverse;
        flex-wrap: wrap;
    }
}

.recommerceSide {
    padding-right: unset;
    background-color: #616630;

    @media (--viewportMedium) {
        align-items: flex-end;
    }
}

.contentWrapper {
    width: 90%;
    margin: 24px auto;

    @media (--viewportMedium) {
        display: flex;
        flex-direction: column;
        padding: 0 75px;
        justify-content: center;
    }
}
