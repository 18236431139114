@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles//marketplaceDefaults.css';

.root {
}

.img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  height: 217px;

  &:hover {
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
  }
}

.text {
  color: var(--colorBlack);
  font-size: 16px;
  line-height: 18px;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: var(--fontWeightBold);
}

.card {
  display: flex;
  flex-direction: column;
  align-items: start;
  cursor: pointer;
  width: calc(100% - 12px);
  margin-right: 24px;
  background-color: #f5f5f0;

  @media (--viewportMedium) {
    max-width: 500px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px;
}

.dates {
  color: #616d69;
}

.title {
  margin-top: 16px;
  font-weight: var(--fontWeightBold);
  color: var(--colorBlack);
}

.tags {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tag1 {
  border: 1px solid #7b9b9b;
  color: #7b9b9b;
  border-radius: 4px;
  padding: 0 4px;
  font-size: 14px;
}

.tag2 {
  border: 1px solid #616630;
  color: #616630;
  border-radius: 4px;
  padding: 0 4px;
  font-size: 14px;
}
