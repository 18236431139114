@import '../../../../styles/customMediaQueries.css';

.root {
}

.img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  height: 160px;

  @media (--viewportMedium) {
    height: 365px;
  }

  &:hover {
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
  }
}

.brandImg {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;

  &:hover {
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
  }
}

.trendingImg {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;

  &:hover {
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
  }
}

.text {
  color: var(--colorBlack);
  font-size: 16px;
  line-height: 18px;
  margin-top: 20px;
  text-transform: capitalize;
  font-weight: var(--fontWeightBold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-width: 200px;
  margin-right: 12px;

  @media (--viewportMedium) {
    max-width: 500px;
  }
}

.brandCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  max-width: 200px;
  flex: 0 0 auto;
  margin-right: 12px;

  @media (--viewportMedium) {
    max-width: 500px;
    flex: 1;
  }
}

.trendingCard {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-width: 200px;
  flex: 0 0 auto;
  margin-right: 12px;

  @media (--viewportMedium) {
    max-width: 500px;
    flex: 1;
  }
}
