@import '../../styles/customMediaQueries.css';

.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
}

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: calc(var(--zIndexTopbar) + 1);
}

.main {
  width: 100%;
  max-width: 100vw;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: var(--topbarHeight);
}

.fullWrapper {
  width: 100%;

  @media (--viewportLargeWithPaddings) {
    width: 96%;
    margin: 0 auto;
  }
}

.mainWrapper {
  max-width: 96%;
  margin: 0 auto;
  padding: 0 20px;

  @media (--viewportLargeWithPaddings) {
    padding: 0;
  }
}
