@import '../../../../styles/customMediaQueries.css';

.root {
}

.sectionWrapper {
  padding: 32px 0 0;
  margin: auto;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 24px;
}

.line {
  display: none;
  flex: 8;
  height: 1px;
  background-color: var(--colorGrey300);

  @media (--viewportMedium) {
    display: block;
  }
}

.shopAllBtn {
  width: 100px;
  font-size: 16px;

  background-color: var(--marketplaceColor);
  text-transform: uppercase;
  color: var(--colorWhite);
  font-weight: var(--fontWeightSemiBold);
  min-height: 32px;

  border-radius: 8px;

  @media (--viewportMedium) {
    width: auto;
    min-height: 42px;

    border-radius: 8px;
    padding: 0 30px;
  }

  &:hover,
  &:focus {
    background-color: var(--marketplaceColorDark);
  }
}

.title {
  font-family: 'Oswald', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 20px;
  text-transform: uppercase;
  color: var(--colorBlack);
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    font-size: 32px;
  }
}

.row {
  display: block;
  width: 100%;
  max-width: 100vw;
}

.items {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  gap: 20px;
  padding: 30px 0;
}

.slider {
  margin: 24px 0 24px 24px;
}
